
import { getContextualisedPageData } from '~/utils/helpers/data';
import getHead from '~/utils/get-head';

export default {
  name: 'HomePage',
  async asyncData(context) {
    const { error } = context;
    const data = await getContextualisedPageData(context, 'homepage');
    if (typeof data === 'undefined') {
      error({
        statusCode: 404,
      });
    }
    return {
      url: data?.url,
      slices: data?.slices,
    };
  },
  head() {
    return getHead(this, [], null, { hasOrganization: true });
  },
};
