export default (data, locale, url) => {
  const head = {
    title: data.title,
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: data.description,
      },
      {
        hid: 'robots',
        name: 'robots',
        content: `${data.noindex ? 'noindex' : 'index'}, ${
          data.nofollow ? 'nofollow' : 'follow'
        }`,
      },
    ],
    link: [
      {
        rel: 'canonical',
        href:
          data?.canonicalUrl?.link_type === 'Web'
            ? data?.canonicalUrl?.url
            : url,
      },
    ],
  };

  head.meta.push(
    { hid: 'twitter:title', name: 'twitter:title', content: data.title },
    {
      hid: 'twitter:description',
      name: 'twitter:description',
      content: data.description,
    },
    { hid: 'twitter:image', name: 'twitter:image', content: data.image },
    { hid: 'og:locale', propety: 'og:locale', content: locale },
    { hid: 'og:title', property: 'og:title', content: data.title },
    { hid: 'og:site_name', property: 'og:site_name', content: data.siteName },
    {
      hid: 'og:description',
      property: 'og:description',
      content: data.description,
    },
    { hid: 'og:image', property: 'og:image', content: data.image },
    {
      hid: 'apple-mobile-web-app-title',
      name: 'apple-mobile-web-app-title',
      content: data.siteName,
    },
    {
      hid: 'application-name',
      name: 'application-name',
      content: data.siteName,
    }
  );

  if (url) {
    head.meta.push({ hid: 'og:url', property: 'og:url', content: url });
    head.meta.push({ hid: 'twitter:url', name: 'twitter:url', content: url });
  }
  return head;
};
