import { specificUrls } from './urls';

export function getDataiadsScript(url) {
  if (specificUrls.includes(url)) {
    return {
      type: 'text/javascript',
      hid: 'dataiads-snippet',
      async: true,
      innerHTML: `
        window.dataiadsConfig = { appId: 'april', collectorURL: 'https://april.dataiads.io' };
        (function (p, l, o, w, i, n, g) {
          if (!p[i]) {
            p.GlobalDataiadsNamespace = p.GlobalDataiadsNamespace || [];
            p.GlobalDataiadsNamespace.push(i);
            p[i] = function () {
              (p[i].q = p[i].q || []).push(arguments);
            };
            p[i].q = p[i].q || [];
            n = l.createElement(o);
            g = l.getElementsByTagName(o)[0];
            n.async = 1;
            n.src = w;
            g.parentNode.insertBefore(n, g);
            dataiads('newTracker', 'dataiads', dataiadsConfig.collectorURL, {
              appId: dataiadsConfig.appId,
              postPath: '/io.dataiads/collect',
              respectDoNotTrack: true,
              cookieSameSite: 'Lax',
              discoverRootDomain: true,
              cookieName: 'dataiads-analytics',
              stateStorageStrategy: 'cookieAndLocalStorage',
            });
            dataiads('addPlugin:dataiads', 'https://cdn.jsdelivr.net/npm/@snowplow/browser-plugin-client-hints@latest/dist/index.umd.min.js', [
              'snowplowClientHints',
              'ClientHintsPlugin',
            ]);
            dataiads('addPlugin:dataiads', 'https://cdn.jsdelivr.net/npm/@snowplow/browser-plugin-performance-timing@latest/dist/index.umd.min.js', [
              'snowplowPerformanceTiming',
              'PerformanceTimingPlugin',
            ]);
            dataiads('addPlugin', 'https://cdn.jsdelivr.net/npm/@snowplow/browser-plugin-snowplow-ecommerce@latest/dist/index.umd.min.js', [
              'snowplowEcommerceAccelerator',
              'SnowplowEcommercePlugin',
            ]);
            if (dataiadsConfig.debug) {
              dataiads(
                'addPlugin:dataiads',
                'https://cdn.jsdelivr.net/npm/@snowplow/browser-plugin-debugger@latest/dist/index.umd.min.js',
                ['snowplowDebugger', 'DebuggerPlugin'],
                [4]
              );
            }
            var getCookie = function (name) {
              var cookie = document.cookie.match('(^|;)s*' + name + 's*=s*([^;]+)');
              return cookie ? cookie.pop() : '';
            };
            var cookiesContextGenerator = function () {
              var dataiadsCookie = getCookie('dataiads');
              var dataiadsSessionCookie = getCookie('dataiadsS');
              return {
                schema: 'iglu:io.dataiads/dataiads_cookies/jsonschema/1-0-0',
                data: {
                  dataiads_cookie: dataiadsCookie || '',
                  dataiads_session_cookie: dataiadsSessionCookie || '',
                },
              };
            };
            dataiads('addGlobalContexts:dataiads', [cookiesContextGenerator]);
          }
        })(window, document, 'script', window.dataiadsConfig.collectorURL + '/io.dataiads/client.js', 'dataiads');
      `,
    };
  }
  return null;
}
