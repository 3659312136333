export const specificUrls = [
  '/landing-page/devis-complementaire-sante',
  '/landing-page/comparer-complementaires-sante-senior',
  '/landing-page/comparer-les-complementaires-sante',
  '/landing-page/comparer-complementaire-sante-senior',
  '/landing-page/comparer-complementaires-sante',
  '/complementaire-sante/assurance-sante-senior',
  '/landing-page/assurance-sante',
  '/landing-page/comparer-les-complementaires-sante-v4',
];
